import React from 'react'
import Img from 'gatsby-image'
import Zoom from 'react-medium-image-zoom'

import 'react-medium-image-zoom/dist/styles.css'

const Project = (props) => (
    <div className="section-wrapper">
      <div className={`project index-${props.index}`}>
        <div className="project-name">{props.title.text.toUpperCase()}</div>
        <div className="project-description">{props.desc.text}</div>
        <div className="project-gallery">
          <Zoom zoomMargin={30} transitionDuration={250}>
            <div className="project-img">
              <Img fluid={props.image1.localFile.childImageSharp.fluid} alt={props.image1.alt} />
            </div>
          </Zoom>
          <Zoom zoomMargin={30} transitionDuration={250}>
            <div className="project-img">
              <Img fluid={props.image2.localFile.childImageSharp.fluid} alt={props.image2.alt}/>
            </div>
          </Zoom>
          <Zoom zoomMargin={30} transitionDuration={250}>
            <div className="project-img">
              <Img fluid={props.image3.localFile.childImageSharp.fluid} alt={props.image3.alt}/>
            </div>
          </Zoom>
        </div>
      </div>
    </div >
  )

export default Project