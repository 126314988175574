import React from "react";

const Contact = () => (
  <div className="section section-contact">
    <div className="section-title">
      Contact Us
        </div>
    <div className="section-wrapper">
      <div className="form-wrapper">
        <p>We offer free quotations as part of our service. For more information, send us a message using the form below.</p>
        <form className="contact-form" name="contact-form" method="POST" data-netlify="true">
          <input type="hidden" name="contact" value="contact-form" />
          <input placeholder="Name" className="form-field" type="text" name="name" required />
          <input placeholder="Email" className="form-field" type="email" name="email" required />
          <input placeholder="Email" className="form-field" type="email" name="email" required />
          <textarea placeholder="Message" className="form-field" name="message" />
          <button className="contact-submit" type="submit" onClick={trackSubmit()}>SEND</button>
        </form>
      </div>
    </div>
  </div >
)

const trackSubmit = () => {
  if (typeof window !== "undefined" && typeof window.ga !== "undefined") {
    window.ga('send', 'event', 'Contact Form', 'Submit', 'Submission Attempt');
  }
}

export default Contact