import React from "react"
import PropTypes from "prop-types"
import Fade from 'react-reveal/Fade'
import Img from 'gatsby-image'

const Header = ({ siteTitle, nav }) => (
  <div className="section section-header">
    <Fade top>
      <Img className="logo" fluid={nav.edges[0].node.data.logo.localFile.childImageSharp.fluid} />
    </Fade>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header