import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/project"
import "../style/style.scss"
import Hero from '../components/hero'
import Services from '../components/services'



const IndexPage = ({
  data: { hero, projects, nav },
}) => (
    <Layout nav={nav}>
      <Hero hero={hero} />
      <Services />
      <div className="section section-projects">
        <div className="section-title">
          Recent Projects
        </div>
        <SEO title="Victorian and Edwardian Tile Restoration" keywords={['restoration', 'repair', 'tiles', 'tiled', 'floor', 'hallway', 'victorian', 'mosaic', 'geometric', 'encaustic', 'tile', 'london']} />
        {projects.edges.map((project, index) => (
          <Project uid={project.node.uid}
            index={index % 2}
            desc={project.node.data.project_description}
            image1={project.node.data.image1}
            image2={project.node.data.image2}
            image3={project.node.data.image3}
            title={project.node.data.title}
          />
        ))}
      </div>
    </Layout >
  )

export const IndexQuery = graphql`
query {
	nav: allPrismicNav {
		edges {
			node {
				data {
					logo {
						localFile {
							childImageSharp {
								fluid(maxWidth: 180, maxHeight: 180, quality: 70, cropFocus: ENTROPY) {
									...GatsbyImageSharpFluid_noBase64
								}
							}
						}
					}
				}
			}
		}
	}
	hero: allPrismicHero {
		edges {
			node {
				data {
					hero_text {
						text
					}
					image1 {
						localFile {
							childImageSharp {
								fluid(maxWidth: 490, maxHeight: 600, quality: 60, cropFocus: ENTROPY) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
	projects: allPrismicProject {
		edges {
			node {
				uid
				data {
					image1 {
            alt
						localFile {
							childImageSharp {
								fluid(maxWidth: 490, maxHeight: 600, quality: 60, cropFocus: ENTROPY) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					image2 {
            alt
						localFile {
							childImageSharp {
								fluid(maxWidth: 490, maxHeight: 600, quality: 60, cropFocus: ENTROPY) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					image3 {
            alt
						localFile {
							childImageSharp {
								fluid(maxWidth: 490, maxHeight: 600, quality: 60, cropFocus: ENTROPY) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					title {
						text
					}
					project_description {
						text
					}
				}
			}
		}
	}
}
`;

export default IndexPage