import React from 'react'
import Fade from 'react-reveal/Fade'


const Services = () => (
  <div className="section section-services">
    <Fade>
      <div className="section-title">
        Services
        </div>
      <div className="section-wrapper">
        <ul>
          <li>Free consultation and quotation</li>
          <li>Consolidation of substrate and replacement of damaged tiles</li>
          <li>Reproduction of encaustic tiles</li>
          <li>Alteration, correction and extension of the tile scheme</li>
          <li>Deep clean and seal</li>
        </ul>

        <div>We offer an initial consultation to estimate the degree of damage and to advise on the appropriate method of restoration. We aim to retain as much of the historic material as possible and stabilise the floor to prevent any further damage, while reviving its appearance to a high standard of cleanliness for everyday use.</div>
      </div>
    </Fade>
  </div>
)

export default Services