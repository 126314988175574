import React from "react"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade'


const Hero = ({ hero }) => (
  <div className="section section-hero">
      <h1 className="site-title">
      <Fade> {hero.edges[0].node.data.hero_text.text}</Fade>
      </h1>
      <h6>Restoration - Extension - Cleaning - Sealing</h6>
    
    <div className="hero-image">
      {//<Img fluid={hero.edges[0].node.data.image1.localFile.childImageSharp.fluid} />
      }
    </div>
  </div>
)

export default Hero;