import React from 'react'
import { IoMdArrowForward } from 'react-icons/io';


const Footer = () => (
  <div className="section section-footer">
    <div className="email-box-wrapper">
      <a className="email-box" target="_blank" href="mailto:info@zagorestoration.co.uk?subject=Restoration%20enquiry">
        <IoMdArrowForward className="icon" />
        <div className="email">
          <span>info@zagorestoration.co.uk</span>
        </div>
      </a>
    </div>
  </div>
)

export default Footer